<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-row justify="center">
    <v-snackbar top
                v-model="snackbar" :color="color"
                timeout="2000"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
    <v-col cols="12" class="px-10">
      <v-dialog
          v-model="dialog"
          max-width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              absolute
              x-large
              right
              color="primary"
              dark
              icon
              v-bind="attrs"
              v-on="on"
              @click="createBook"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-center">
            <span class="headline">Book details</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <form @submit.prevent="editMode ? update() : submit()">
                <validation-observer
                    ref="observer"
                    v-slot="{ invalid }"
                >
                  <v-row>
                    <v-col
                        class="d-flex"
                        cols="12"
                    >
                      <v-select
                          v-model="status"
                          :items="['available','unavailable']"
                          label="Status"
                      ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                    >
                      <validation-provider
                          v-slot="{ errors }"
                          name="Title"
                          rules="required"
                      >
                        <v-text-field
                            v-model="title"
                            :error-messages="errors"
                            label="Book title"
                            required
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                    >
                      <validation-provider
                          v-slot="{ errors }"
                          name="Price"
                          rules="required"
                      >
                        <v-text-field
                            v-model="price"
                            :error-messages="errors"
                            label="Book price"
                            required
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                    >
                      <validation-provider
                          v-slot="{ errors }"
                          name="Discount"
                      >
                        <v-text-field
                            v-model="discount"
                            :error-messages="errors"
                            label="Book discount"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col
                        cols="12"
                    >
                      <v-file-input
                          accept="image/*"
                          label="Cover photo"
                          v-model="photo"
                      ></v-file-input>
                    </v-col>
                    <v-col
                        cols="12"
                    >
                      <tiptap-vuetify
                          v-model="description"
                          :extensions="extensions"
                          :toolbar-attributes="{ color: 'yellow' }"
                          placeholder="Enter book description"
                      >
                      </tiptap-vuetify>
                    </v-col>
                    <v-col
                        cols="12"
                    >
                      <v-btn
                          block
                          rounded
                          color="green"
                          class="mr-4"
                          type="submit"
                          :disabled="invalid"
                          :loading="loading"
                      >
                        {{ editMode ? 'update' : 'submit' }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </validation-observer>
              </form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col cols="12">
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Cover
            </th>
            <th class="text-left">
              Title
            </th>
            <th>
              Price
            </th>
            <th>
              Discount
            </th>
            <th>
              Actions
            </th>
          </tr>
          </thead>
          <draggable
              v-model="books"
              tag="tbody"
          >
            <tr
                v-for="book in books"
                :key="book.id"
            >
              <td>
                <v-img
                    :max-width="50"
                    :src="book.photo"
                ></v-img>
              </td>
              <td>
                <router-link :to="{name: 'InsertBookPhoto',params:{bookId: book.id }}">
                  {{ book.title }}
                </router-link>
              </td>
              <td>{{ book.price }}</td>
              <td>{{ book.discount }}</td>
              <td>
                <v-btn icon color="blue" @click="edit(book)">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon color="red" @click="deleteBook(book)">
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </draggable>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>
<script>
import {required} from 'vee-validate/dist/rules'
import axios from 'axios'
import {extend, ValidationObserver, ValidationProvider, setInteractionMode} from 'vee-validate'
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from 'tiptap-vuetify'
import Swal from "sweetalert2";
import draggable from "vuedraggable";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    TiptapVuetify,
    draggable,
  },
  data: () => ({
    loading: false,
    books: [],
    selectedBook: null,
    editMode: false,
    dialog: false,
    drag: false,
    status: 'available',
    title: '',
    description: '',
    price: '',
    discount: '',
    photo: null,
    snackbar: false,
    text: '',
    color: 'blue',
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      Bold,
      Link,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ],
  }),
  computed: {
    form: function () {
      let formData = new FormData();
      if (this.editMode) {
        formData.append('_method', 'PUT')
      }
      formData.append('title', this.title)
      formData.append('description', this.description ? this.description : '')
      formData.append('price', this.price)
      formData.append('discount', this.discount)
      formData.append('status', this.status)
      if (this.photo) {
        formData.append('photo', this.photo)
      }
      return formData
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    },
  },
  methods: {
    sortItems() {
      const url = 'admin/books'
      let data = {
        books: this.books,
        type: 'sort'
      }
      axios.post(url, data).then(() => {
      })
    },
    createBook() {
      this.editMode = false;
      this.selectedBook = null;
      this.title = '';
      this.description = '';
      this.price = '';
      this.discount = '';
      this.photo = null;
    },
    edit(book) {
      this.dialog = true;
      this.editMode = true;
      this.title = book.title;
      this.description = book.description ? book.description : '';
      this.price = book.price;
      this.discount = book.discount;
      this.selectedBook = book;
    },
    submit() {
      const url = 'admin/books';
      this.loading = true;
      axios.post(url, this.form).then(() => {
        this.loading = false;
        this.clear();
        this.color = 'green';
        this.snackbar = true;
        this.text = 'New book created';
        this.loadBooks();
      })
    },
    update() {
      const url = 'admin/books/' + this.selectedBook.id;
      this.loading = true;
      axios.post(url, this.form).then(() => {
        this.loading = false;
        this.clear();
        this.color = 'green';
        this.snackbar = true;
        this.text = 'Book updated';
        this.loadBooks();
      })
    },
    loadBooks() {
      const url = 'admin/books?type=admin';
      axios.get(url).then((response) => {
        this.editMode = false;
        this.books = response.data;
      })
    },
    deleteBook(book) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          const url = 'admin/books/' + book.id;
          axios.delete(url).then(() => {
            this.loadBooks();
            Swal.fire(
                'Deleted!',
                '',
                'success'
            )
          }).catch(() => {
            Swal.fire(
                'Failed!',
                'Something went wrong, try again',
                'warning'
            )
          })
        }
      });
    },
    clear() {
      this.title = '';
      this.description = '';
      this.price = '';
      this.discount = '';
      this.photo = null;
      this.dialog = false;
      this.selectedBook = null;
      this.$refs.observer.reset();
    },
  },
  mounted() {
    this.loadBooks();
  },
  watch: {
    books(newVal, oldVal) {
      if (oldVal.length) {
        this.sortItems()
      }
    }
  }
}

setInteractionMode('eager');
extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})
</script>
<style>
.button {
  margin-top: 35px
}

.flip-list-move {
  transition: transform 0.5s
}

.no-move {
  transition: transform 0s
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb
}

.list-group {
  min-height: 20px
}

.list-group-item {
  cursor: move
}

.list-group-item i {
  cursor: pointer
}
</style>